import type { Attachment, IFabAction } from '@nexdynamic/squeegee-common';
import { observable } from 'aurelia-framework';
import { AttachmentActions } from '../../Attachments/AttachmentActions';
import { Data } from '../../Data/Data';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { Prompt } from '../../Dialogs/Prompt';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import type { Subscription } from '../../Events/SqueegeeEventAggregator';
import type { Filter } from '../../Filters/Filter';
import { FiltersDialog } from '../../Filters/FiltersDialog';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import type { IAttachmentsFilterItemDictionary } from '../AttachmentFilters';
import * as AttachmentFilters from '../AttachmentFilters';
import { filterMenu } from '../AttachmentFilters';
import { AttachmentService } from '../AttachmentService';

export class SelectAttachmentsDialog extends CustomDialog<Array<Attachment>> {
    @observable searchText: string;
    searchTextChanged(): void {
        this.filterAndSort();
    }

    private attachments: Array<Attachment> = [];
    protected filteredAttachments: Array<Attachment & { selected: boolean }> = [];
    protected currentFilter: Filter<IAttachmentsFilterItemDictionary> = filterMenu();

    private _dataRefreshedSub: Subscription;

    private selectOne?: boolean;
    private isPublic?: boolean;

    constructor(public selectedAttachments: Array<Attachment> = [], options?: { selectOne?: boolean; isPublic?: boolean }) {
        super('selectAttachmentsDialog', '../Attachments/Dialogs/SelectAttachmentsDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            isSecondaryView: true,
            cssClass: 'details-dialog no-nav-shadow',
        });
        this.selectOne = options?.selectOne;
        this.isPublic = options?.isPublic;
        this.fabActions = this.getActions();
    }

    private getActions(): Array<IFabAction> {
        const fabActions: Array<IFabAction> = [
            {
                tooltip: 'general.upload-file',
                actionType: 'action-upload',
                handler: this._delegateAddNewAttachment,
                roles: ['Owner', 'Admin', 'Creator', 'Planner', 'Reporting', 'Worker'],
            },
        ];

        return fabActions;
    }

    private _delegateAddNewAttachment = async () => {
        await AttachmentActions.addNew(undefined, this.isPublic);
    };

    async init() {
        this.loadData();
        this._dataRefreshedSub = DataRefreshedEvent.subscribe(
            (event: DataRefreshedEvent) => event.hasAnyType('linkers', 'attachments') && this.loadData()
        );
    }

    dispose() {
        if (this._dataRefreshedSub) this._dataRefreshedSub.dispose();
        super.dispose();
    }

    loadData() {
        const prevAttachmentIds = new Set(this.attachments.map(a => a._id));

        this.attachments = AttachmentService.getAll().filter(
            attachment => this.selectedAttachments.findIndex(a => a._id === attachment._id) === -1
        );

        if (prevAttachmentIds.size > 0) {
            const newAttachments = this.attachments.filter(attachment => !prevAttachmentIds.has(attachment._id));
            if (this.selectOne) {
                // can auto complete this dialog as we only need one attachment
                this.select(newAttachments[0]);
            } else {
                this.selectedAttachments.push(...newAttachments);
            }
        }

        this.filterAndSort();
    }

    private filterAndSort() {
        if (!this.filteredAttachments) this.filteredAttachments = [];
        AttachmentFilters.filterList(this.attachments, this.filteredAttachments, this.searchText, this.currentFilter);
        this.filteredAttachments = AttachmentFilters.sortList(this.filteredAttachments, this.currentFilter).map(
            (attachment: Attachment & { selected: boolean }) => {
                attachment.selected = this.selectedAttachments.findIndex(a => a._id === attachment._id) !== -1;
                return attachment;
            }
        );
    }

    protected async removeAttachment(attachment: Attachment) {
        try {
            const attachmentName = attachment.name;
            //Todo get number of attachments
            const prompt = new Prompt('attachments.confirm-remove', 'attachment.already-attached-to-other-items', {
                okLabel: 'general.remove',
                localisationParams: { attachmentName },
            });

            await prompt.show();

            if (!prompt.cancelled) {
                await AttachmentService.delete(attachment);
                // if this attachment is not attached to any other resources then offer to delete the attachment permanently
                const attachmentIndex = this.attachments.findIndex(a => a._id === attachment._id);
                // Remove from global array
                this.attachments.splice(attachmentIndex, 1);
                this.filterAndSort();
            }
        } catch (error) {
            new NotifyUserMessage('action.remove-attachment-error', { name: attachment.name });
        }
    }

    protected async openFilterMenu() {
        const dialog = new FiltersDialog<IAttachmentsFilterItemDictionary>(this.currentFilter, filterMenu);

        const result = await dialog.show(DialogAnimation.SLIDE_UP);
        if (!dialog.cancelled) {
            this.currentFilter = result;
            this.filterAndSort();
        }
    }
    async getResult() {
        return this.selectedAttachments.splice(0);
    }
    protected clearFilters() {
        this.currentFilter = filterMenu();
        this.filterAndSort();
    }

    select = async (attachment: Attachment) => {
        if (this.selectOne) {
            if (this.isPublic && !attachment.isPublic) {
                const makePublic = await new Prompt('attachments.make-public-title', 'attachments.make-public-description', {
                    okLabel: 'attachments.make-public-ok',
                    cancelLabel: 'general.cancel',
                }).show();
                if (!makePublic) {
                    return this.cancel();
                }
            }
            attachment.isPublic = true;
            await Data.put(attachment);
            this.selectedAttachments = [attachment];
            return this.ok(this.selectedAttachments);
        }
        this.selectedAttachments.push(attachment);
    };

    deselect = (attachment: Attachment) => {
        this.selectedAttachments.splice(this.selectedAttachments.indexOf(attachment), 1);
    };
}
